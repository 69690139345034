import { makeStyles } from "@material-ui/styles";
import { Theme, createStyles } from "@material-ui/core";

export const AuctionIconStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContentText: {
      paddingTop: "8px",
    },
    cellWidth: {
      minWidth: "350px",
    },
    dialogContentSubText: {
      paddingTop: "0",
    paddingBottom: "0",
    paddingLeft: "24px",
      paddingRight: "24px"
    }
  })
);
